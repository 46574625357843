import React from "react"
// import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"


function SEO({ page, pagination, data }) {
  const { site, shareImage } = data;
  const { seo, titolo, sottotitolo, immagineHero } = page;
  const ogTitle = seo ? (seo.ogtitle || seo.title) : titolo;
  const ogDesc = (seo ? (seo.ogdescription || seo.description) : sottotitolo) || site.siteMetadata.description;
  const ogImage = (seo && seo.ogimage ? seo.ogimage.fixed.src : (immagineHero && immagineHero.fluid && immagineHero.fluid.src)) || shareImage.fixed.src;
  const meta = seo && seo.robots ? [{ name: 'robots', content: seo.robots}] : [];
  const linkPagination = [];
  const domain = site.siteMetadata.siteUrl;
  if (pagination){
    linkPagination.push({ href: `${domain}${pagination.path}/`, rel: 'canonical'});
    if (pagination.current > 0){
      linkPagination.push({ href: `${domain}${pagination.path}/${pagination.current === 1 ? '' : `#${pagination.current}`}`, rel: 'prev'});
    }
    if (pagination.current < pagination.length - 1 ){
      linkPagination.push({ href: `${domain}${pagination.path}/#${pagination.current+2}`, rel: 'next'});
    }
  }
  const link = linkPagination || [];

  return (
    <Helmet
      htmlAttributes={{
        lang: 'it',
      }}
      link={link}
      title={seo ? seo.title : `${titolo}: ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: (seo ? seo.description : sottotitolo) || site.siteMetadata.description,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:image`,
          content: ogImage ? ogImage.replace(/^\/\//, 'https://') : ogImage,
        },
        {
          property: `og:description`,
          content: ogDesc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    >
      {/* OneTrust Cookies Consent Notice start for www.veravitaassicurazioni.it */}
      <script type="text/javascript" src="https://cdn.cookielaw.org/consent/018fbf6f-172c-7a8f-9bd5-5d538e89ff0a/OtAutoBlock.js" ></script>
      <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-document-language="true" type="text/javascript" charset="UTF-8" data-domain-script="018fbf6f-172c-7a8f-9bd5-5d538e89ff0a" ></script>
      <script type="text/javascript">
        {`
          function OptanonWrapper() { }
        `}
      </script>
      {/* OneTrust Cookies Consent Notice end for www.veravitaassicurazioni.it */}
    </Helmet>
  )
}

export default SEO;
export const fragment = graphql`
fragment DefaultSeo on Query {
  site {
    siteMetadata {
      title
      siteUrl
      description
      author
    }
  }
  shareImage: contentfulAsset(contentful_id: {eq: "LDrHViAb9xERCS35wYSU2"}) {
    fixed(width: 1920, quality: 100) {
      src
    }
  }
}`
