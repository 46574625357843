const slugify = require('slugify');
const mySlugify = (titolo) => slugify(titolo.toLowerCase().replace(/\[piu\]/g, 'piu'));
const slugPage = page => page.seo && page.seo.friendlyUrl ? page.seo.friendlyUrl : mySlugify(page.titolo);

const replaceHtml = html => {
  const htmlPiu = html.replace(/\[più\]/g, '<span class="purple">più</span>');
  if (process.env.GATSBY_ACTIVE_ENV !== 'prod'){
    return htmlPiu;
  }
  return htmlPiu.replace(/"\/\/assets\.ctfassets\.net\/([^"]+)"/g,'"/$1"');
}

module.exports = {
  slugPage,
  replaceHtml
}