import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import { Link } from "./link";
import { slugPage } from "../utility/utils";

const Header = ({ data }) => {
  const { chisiamo, prodotti, quotazioni, partner, logo } = data;
  const menu = [chisiamo, prodotti, quotazioni, partner];

  const [open, setOpen] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const [hoverItem, setHoverItem] = useState(null);
  const [openMap, setOpenMap] = useState({});

  useEffect(() => {
    if (hoverItem) {
      const subItem = document.querySelector(
        `.navigation__sub-item[data-id=item-${hoverItem}] .navigation__subchild`
      );
      if (subItem) {
        const height = subItem.clientHeight;
        setDropdownHeight(height);
        return () => setDropdownHeight(0);
      }
    }
  }, [hoverItem]);

  useEffect(() => {
    if (!open) {
      setOpenMap({});
    }
  }, [open]);

  return (
    <header className="header">
      <div className="container h-100">
        <div className="d-flex flex-column flex-lg-row align-items-lg-center">
          <div className="d-flex align-items-center flex-fill">
            <div className="logo">
              <Link to="/" title="VeraVita" className="logo__link">
                <Img
                  fluid={logo.childImageSharp.fluid}
                  objectFit="cover"
                  objectPosition="center center"
                  alt="logo VeraVita"
                  className="logo__img"
                  fadeIn={false}
                />
              </Link>
            </div>
            <div className="d-lg-none ml-auto">
              <div
                className={`hamburger${open ? " open" : ""}`}
                onClick={() => setOpen((open) => !open)}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
          <div className="ml-lg-auto text-lg-right">
            <nav
              className={`navigation${open ? " open" : ""}`}
              role="navigation"
            >
              <div className="navigation__wrapper">
                <div>
                  <Link
                    to="/area-riservata/"
                    title="Area Riservata"
                    className="btn-link"
                  >
                    Area Riservata
                  </Link>
                </div>
                <ul
                  className="navigation__list d-lg-flex"
                  role="menu"
                  aria-orientation="horizontal"
                >
                  {menu
                    .filter((e) => !!e)
                    .map((item, i) => (
                      <li
                        className={`navigation__item${
                          openMap[`${i}`] ? " open" : ""
                        }`}
                        role="menuitem"
                        key={i}
                      >
                        <Link
                          to={`/${slugPage(item)}/`}
                          title={item.titolo}
                          className="navigation__link"
                        >
                          {item.titolo}
                        </Link>
                        {item.pagineFiglie && (
                          <>
                            <span
                              className="navigation__arrow d-lg-none"
                              onClick={() =>
                                setOpenMap((map) => ({
                                  ...map,
                                  [`${i}`]: !map[`${i}`],
                                }))
                              }
                            ></span>
                            <div
                              className="navigation__dropdown text-left"
                              style={{
                                height:
                                  dropdownHeight > 0
                                    ? `${dropdownHeight + 40}px`
                                    : "auto",
                              }}
                            >
                              <div className="container">
                                <ul className="navigation__sub">
                                  {item.pagineFiglie.map((subItem, j) => (
                                    <li
                                      className={`navigation__sub-item${
                                        hoverItem === `${i}-${j}`
                                          ? " hover"
                                          : ""
                                      }${openMap[`${i}-${j}`] ? " open" : ""}`}
                                      key={j}
                                      data-id={`item-${i}-${j}`}
                                      onMouseEnter={() =>
                                        setHoverItem(`${i}-${j}`)
                                      }
                                      onMouseLeave={() =>
                                        setHoverItem((value) =>
                                          value === `${i}-${j}` ? null : value
                                        )
                                      }
                                    >
                                      <Link
                                        to={`/${slugPage(item)}/${slugPage(
                                          subItem
                                        )}/`}
                                        title={subItem.titolo}
                                        className="navigation__dropdown-link"
                                      >
                                        {subItem.titolo}
                                      </Link>
                                      {subItem.pagineFiglie &&
                                        !subItem.pagineFiglie.find(
                                          (item) =>
                                            item.pagineFiglie &&
                                            !!item.pagineFiglie[0]
                                              .prodotto_archiviato
                                        ) && (
                                          <>
                                            <span
                                              className="navigation__arrow d-lg-none"
                                              onClick={() =>
                                                setOpenMap((map) => ({
                                                  ...map,
                                                  [`${i}-${j}`]: !map[
                                                    `${i}-${j}`
                                                  ],
                                                }))
                                              }
                                            ></span>
                                            <div className="navigation__subchild">
                                              <ul className="navigation__subchild-list">
                                                {subItem.pagineFiglie &&
                                                  subItem.pagineFiglie.map(
                                                    (subSubItem, k) => (
                                                      <li
                                                        className="navigation__subchild-item"
                                                        key={k}
                                                      >
                                                        <Link
                                                          to={`/${slugPage(
                                                            item
                                                          )}/${slugPage(
                                                            subItem
                                                          )}/${slugPage(
                                                            subSubItem
                                                          )}${
                                                            subSubItem.pagineQuotazioni
                                                              ? `-${slugPage(
                                                                  subSubItem
                                                                    .pagineQuotazioni[0]
                                                                )}`
                                                              : ""
                                                          }/`}
                                                          title={
                                                            subSubItem.titolo
                                                          }
                                                          className="navigation__dropdown-link"
                                                        >
                                                          {subSubItem.titolo}
                                                        </Link>
                                                      </li>
                                                    )
                                                  )}
                                              </ul>
                                            </div>
                                          </>
                                        )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
export const fragment = graphql`
  fragment MenuFragment on ContentfulLayoutPaginaInterna {
    titolo
    seo {
      friendlyUrl
    }
    pagineFiglie {
      titolo
      seo {
        friendlyUrl
      }
      pagineFiglie {
        titolo
        seo {
          friendlyUrl
        }
        pagineFiglie {
          prodotto_archiviato {
            contentful_id
          }
        }
        pagineQuotazioni {
          titolo
          seo {
            friendlyUrl
          }
        }
      }
    }
  }
  fragment HeaderFragment on Query {
    chisiamo: contentfulLayoutPaginaInterna(
      contentful_id: { eq: "1iYoozWfYExsxemoMb8KnA" }
    ) {
      ...MenuFragment
    }
    prodotti: contentfulLayoutPaginaInterna(
      contentful_id: { eq: "rPTfNDMOo5vmDqeft90eA" }
    ) {
      ...MenuFragment
    }
    quotazioni: contentfulLayoutPaginaInterna(
      contentful_id: { eq: "1dBGxMWTRWPlthCCXtXD9s" }
    ) {
      ...MenuFragment
    }
    partner: contentfulLayoutPaginaInterna(
      contentful_id: { eq: "1cGHIYZ8IDj1GAEQIN3IEZ" }
    ) {
      ...MenuFragment
    }
    logo: file(relativePath: { eq: "logo-vera-vita.png" }) {
      childImageSharp {
        fluid(maxWidth: 240) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
