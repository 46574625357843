import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import { Link } from "./link";
import { slugPage } from "../utility/utils";

const Footer = ({ data }) => {
  const {
    contatti,
    infoHome,
    privacy,
    cookie,
    reclami,
    conflitti,
    fea,
    polizze,
    accessibilita,
    logoBPM,
    logoCattolica,
  } = data;
  const menu = [
    infoHome,
    privacy,
    cookie,
    reclami,
    fea,
    contatti,
    {
      titolo: "Arbitro per le Controversie Finanziarie",
      link: "http://www.acf.consob.it/",
      target: "_blank",
    },
    conflitti,
    polizze,
    accessibilita,
  ];

  return (
    <footer className="footer text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <ul className="footer__list">
              {menu
                .filter((e) => !!e)
                .map((item, i) => (
                  <li className="footer__item" key={i}>
                    <Link
                      to={item.link ? item.link : `/${slugPage(item)}/`}
                      title={item.titolo}
                      target={item.target ? item.target : "_self"}
                      className="footer__link"
                    >
                      {item.titolo}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="d-lg-flex align-items-end">
              <div className="flex-fill order-sm-2">
                <ul className="footer__list footer__list--copyright">
                  <li className="footer__item">
                    Copyright © Vera Vita Cod. Fisc. e P.Iva 03035950231
                  </li>
                  <li className="footer__item"></li>
                </ul>
              </div>
              <div className="footer__banco order-sm-1">
                <a
                  href="https://www.bancobpm.it/"
                  title="Banco BPM"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img
                    fluid={logoBPM.childImageSharp.fluid}
                    alt="logo Banco BPM"
                    objectFit="cover"
                    objectPosition="center center"
                  />
                </a>
              </div>
              <div className="footer__cattolica order-sm-3"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <ul className="footer__list mt-2">
              <li className="footer__item">
                <a
                  href="https://www.ariadnedigital.it/"
                  title="Ariadne Digital"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer__link"
                >
                  Engineered by Ariadne Digital
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
export const fragment = graphql`
  fragment FooterFragment on ContentfulLayoutPaginaInterna {
    titolo
    seo {
      friendlyUrl
    }
  }
  fragment FooterRootFragment on Query {
    contatti: contentfulLayoutPaginaInterna(
      contentful_id: { eq: "3PBRDCqs2kUJa6r23LYbXx" }
    ) {
      ...FooterFragment
    }
    infoHome: contentfulLayoutPaginaInterna(
      contentful_id: { eq: "1m35mWIskCou1O18syC47k" }
    ) {
      ...FooterFragment
    }
    privacy: contentfulLayoutPaginaInterna(
      contentful_id: { eq: "Y862ew7grxt8EhiySErlF" }
    ) {
      ...FooterFragment
    }
    cookie: contentfulLayoutPaginaInterna(
      contentful_id: { eq: "2ChGvYretj2MeaQAfx4r05" }
    ) {
      ...FooterFragment
    }
    reclami: contentfulLayoutPaginaInterna(
      contentful_id: { eq: "2gYHpTSyfJcAiJt61tehCq" }
    ) {
      ...FooterFragment
    }
    conflitti: contentfulLayoutPaginaInterna(
      contentful_id: { eq: "6Zs4XtIJhFnM4m9dOsrQjV" }
    ) {
      ...FooterFragment
    }
    fea: contentfulLayoutPaginaInterna(
      contentful_id: { eq: "2Fv90BWLIiz033f9TkVjJy" }
    ) {
      ...FooterFragment
    }
    polizze: contentfulLayoutPaginaInterna(
      contentful_id: { eq: "3l9gr7eTBIx1BIKYDgq0NO" }
    ) {
      ...FooterFragment
    }
    accessibilita: contentfulLayoutPaginaInterna(
      contentful_id: { eq: "5Aj9IQI0zN3TfHhEgVowgt" }
    ) {
      ...FooterFragment
    }
    logoBPM: file(relativePath: { eq: "logo-banco-bpm.png" }) {
      childImageSharp {
        fluid(maxWidth: 155) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoCattolica: file(
      relativePath: { eq: "logo-cattolica-assicurazioni.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 60) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
