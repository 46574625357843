import React from 'react'
import PropTypes from 'prop-types'
import Header from './header'
import Footer from './footer'


const Layout = ({ data, children }) => {
  return (
    <div className="wrapper">
      <Header data={data} siteTitle="Vera Vita" />
      {children}
      <Footer data={data} />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;